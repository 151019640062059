<template>
  <validation-provider
    :name="field.name"
    :rules="rules"
    v-slot="validationContext"
  >
    <b-form-group :label="field.label || ''" :description="field.description || ''">
      <b-form-input
        :type="type"
        :size="size"
        v-model="inputValue"
        :placeholder="field.placeholder"
        :state="getValidationState(validationContext)"
      ></b-form-input>
      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    },
    rules: {
      type: Object
    },
    type: {
      type: String
    },
    size: {
      type: String
    },
    field: {
      type: Object,
      validate: (v) => {
        return !!v;
      }
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    } 
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
}
</script>