<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <p v-if="isNew" class="font-weight-bold">Attention: Verify information before saving!</p>
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row no-gutters>
          <b-col>
            <FormInput :rules="{required:true, alpha_spaces: true}"
              :field="{
                name: 'firstName',
                label: 'First Name',
                placeholder: 'Enter first name'
              }"
              v-model="student.firstName" />
          </b-col>
          <b-col class="ml-2">
            <FormInput :rules="{required:true, alpha_spaces: true}"
              :field="{
                name: 'lastName',
                label: 'Last Name',
                placeholder: 'Enter last name'
              }"
              v-model="student.lastName" />
          </b-col>
        </b-row>
        <FormInput :rules="{ alpha_spaces: true }"
          :field="{
            name: 'middleName',
            label: 'Middle Name',
            placeholder: 'Enter middle name if applicable'
          }"
          v-model="student.middleName" />
        <FormInput :rules="{ required: true, email: true }"
          :field="{
            name: 'email',
            label: 'Contact Email',
            placeholder: 'Enter email',
            description: 'Double check email and make sure there is no typo.'
          }"
          v-model="student.email" />
        <FormGroupSelect ref="groupSelect" :rules="{ required: true }"
          :field="{
            name: 'group',
            label: 'Group',
            placeholder: 'Enter group name',
            description: ''
          }"
          v-model="groupId" />
        <div v-if="!isNew" class="mb-3">
          <b-alert variant="warning" show>
            <span>Warning! Changing country or timezone will make all previously entered schedules unavailable</span>
          </b-alert>
        </div>
        <FormSelect :rules="{ required: true }"
          :field="{
            name: 'country',
            label: 'Country',
            placeholder: 'Select country'
          }"
          :options="countryOptions"
          v-model="student.country" />
        <FormSelect v-if="student.country" :rules="{ required: true }"
          :field="{
            name: 'timezone',
            label: 'Timezone',
            placeholder: 'Select timezone'
          }"
          :options="timezoneOptions"
          v-model="student.timezone" />
        <b-button @click="onCancel" variant="outline-secondary">Cancel</b-button>
        <b-button type="submit" variant="primary" class="float-right">Save <b-icon-check /></b-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import countriesAndTz from 'countries-and-timezones';
import FormInput from '@/components/ui/FormInput';
import FormSelect from '@/components/ui/FormSelect';
import FormGroupSelect from '@/components/ui/FormGroupSelect';
import capitalize from 'capitalize';
//import faker from 'faker';

const countries = countriesAndTz.getAllCountries();
export default {
  props: {
    studentId: {
      type: String
    }
  },
  components: {
    FormInput,
    FormSelect,
    FormGroupSelect
  },
  data() {
    return {
      student: null,
      groupId: null
    }
  },
  computed: {
    isNew() {
      if (!this.student.id) {
        return true;
      } else {
        return false;
      }
    },
    countryOptions() {
      return Object.keys(countries).map((c) => {
        const info = countries[c];
        return {
          text: info.name,
          value: info.id
        };
      }).sort((a, b) => a.text.localeCompare(b.text));
    },
    timezoneOptions() {
      if (!this.student.country) {
        return [];
      }
      const timezones = countriesAndTz.getTimezonesForCountry(this.student.country);
      if (!timezones) {
        return [];
      }
      return timezones.map((tz) => {
        return {
          text: `${tz.name} (${tz.utcOffsetStr})`,
          value: tz.name
        };
      });
    }
  },
  beforeMount() {
    this.student = {...this.$store.getters.student(this.studentId)} || {
      country: null,
      timezone: null
    };
    if (this.student.group) {
      this.groupId = this.student.group.id;
    }
    /*for (let i = 0; i < 50; i++) {
      this.$store.dispatch('createStudent', {
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        middleName: faker.name.lastName(),
        email: faker.internet.email(),
        country: 'PH',
        timezone: 'Asia/Manila'
      });
    }*/
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmit() {
      if (await this.$refs.observer.validate()) {

        // Prepare data for submission
        if (this.student.firstName) {
          this.student.firstName = capitalize.words(this.student.firstName.trim());
        }

        if (this.student.middleName) {
          this.student.middleName = capitalize.words(this.student.middleName.trim());
        }

        if (this.student.lastName) {
          this.student.lastName = capitalize.words(this.student.lastName.trim());
        }

        if (this.student.email) {
          this.student.email = this.student.email.trim().toLowerCase();
        }

        await this.$refs.groupSelect.ensureGroup();
        this.student.group = this.groupId;

        let result = null;
        if (!this.student.id) {
          result = await this.$store.dispatch('createStudent', this.student);
        } else {
          result = await this.$store.dispatch('updateStudent', this.student);
        }
        this.$emit('submit', result);
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>