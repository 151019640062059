<template>
  <validation-provider
    :name="field.name"
    :rules="rules"
    v-slot="validationContext"
  >
    <b-form-group :label="field.label || ''" :description="field.description || ''">
      <b-form-input
        id="inputField"
        :type="type"
        :size="size"
        v-model="textValue"
        :placeholder="field.placeholder"
        :state="getValidationState(validationContext)"
        @focus="onFocus"
        @blur="onBlur"
      ></b-form-input>
      <div id="searchbox" v-show="isShowing">
        <div v-if="!$store.getters.isFetchingGroups">
          <div style="overflow:scroll; max-height: 200px" class="mb-2">
            <div v-for="option in options" :key="option.value"
              class="select-item" @mousedown="onSelection(option)">
              <span>{{option.text}}</span>
            </div>
          </div>
          <div @mousedown.prevent="onAddNewToggle" @click.prevent>
            <b-checkbox v-model="isNew" switch>
              <div style="display: table-cell; vertical-align: middle;">
                <span style="display: inline-block; vertical-align: middle;">Create new group</span>
              </div>
            </b-checkbox>
          </div>
        </div>
        <div v-else class="text-center mb-2 mt-2">
          <span>Loading </span><b-spinner small />
        </div>
        <div id="arrow" data-popper-arrow></div>
      </div>
      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import { createPopper } from '@popperjs/core';

const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      state.elements.reference.offsetWidth
    }px`;
  }
};

export default {
  props: {
    value: {
      type: String
    },
    rules: {
      type: Object
    },
    type: {
      type: String
    },
    size: {
      type: String
    },
    field: {
      type: Object,
      validate: (v) => {
        return !!v;
      }
    }
  },
  data() {
    return {
      isNew: false,
      isShowing: false,
      popper: null,
      objectId: null,
      textValue: ''
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.textValue;
      },
      set(val) {
        this.textValue = val;
        const options = this.options;
        for (let i = 0; i < options.length; i++) {
          if (options[i].text === val) {
            this.$emit('input', options[i].value);
          }
        }
      }
    },
    options() {
      const options = this.$store.getters.groupSelectorList;
      if (this.inputValue === '') {
        return options;    
      } else {
        return options.filter((opt) => {
          return opt.text.indexOf(this.inputValue) >= 0;
        }); 
      }
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async ensureGroup() {
      const group = await this.$store.dispatch('ensureGroupByName', this.textValue);
      this.$emit('input', group.id);
    },
    onSelection(option) {
      this.inputValue = option.text;
    },
    onAddNewToggle() {
      this.isNew = !this.isNew;
    },
    async onFocus() {
      this.$store.dispatch('fetchGroups');
      this.isShowing = true;
      this.$nextTick((function () {
        this.popper.forceUpdate();
      }).bind(this));
    },
    validateSelection(isNew) {
      if (!isNew && !this.isShowing) {
        const options = this.options;
        let optionFound = false;

        for (let i = 0; i < options.length; i++) {
          if (options[i].text === this.inputValue) {
            console.log(options[i].text, this.inputValue);
            optionFound = true;
          }
        }
        console.log(options);
        if (!optionFound) {
          this.inputValue = '';
          this.$emit('input', null);
        }
      }
    },
    onBlur() {
      this.isShowing = false;
      this.validateSelection(this.isNew);
    }
  },
  mounted() {
    const inputFieldNode = document.getElementById('inputField');
    const searchboxNode = document.getElementById('searchbox');
    this.$store.dispatch('fetchGroups');
    
    this.popper = createPopper(inputFieldNode, searchboxNode, {
      placement: 'bottom',
      modifiers: [
        sameWidth,
        {
          name: 'offset',
          options: {
            offset: [0, 1],
          }
        }
      ]
    });

    if (this.options) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].value === this.value) {
          this.textValue = this.options[i].text;
        }
      }
    }
  },
  watch: {
    isNew(newValue) {
      this.validateSelection(newValue);
    },
    options: {
      immediate: true,
      handler(newOptions) {
        const options = newOptions;
        if (options && this.options.length !== newOptions.length) {
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === this.value) {
              this.textValue = options[i].text;
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#searchbox {
  background-color: rgb(255, 255, 255);
  border-color: rgb(187, 187, 187);
  border-width: 1px;
  border-style: solid;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  z-index: 100;
}

.select-item {
  font-size: 0.96rem;
  padding: 5px;
  margin-bottom: 2px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: rgb(243, 243, 243);
  transition: 300ms;
}

.select-item:hover {
  background-color: rgb(214, 214, 214);
  cursor: pointer;
}
</style>