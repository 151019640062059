<template>
  <validation-provider
    :name="field.name"
    :rules="rules"
    v-slot="validationContext"
  >
    <b-form-group :label="field.label || ''" :description="field.description || ''">
      <b-form-input
        type="text"
        :size="size"
        v-model="inputValue"
        :placeholder="'00:00'"
        :state="getValidationState(validationContext)"
        :disabled="disabled"
        v-mask="mask"
      ></b-form-input>
      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { VueMaskDirective } from 'v-mask';
import { timeRangeMask } from '@/utils/time-mask';
export default {
  props: {
    value: {
      type: Number
    },
    rules: {
      type: Object
    },  
    size: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    field: {
      type: Object,
      validate: (v) => {
        return !!v;
      }
    }
  },
  data() {
    return {
      valueStr: '',
      mask: timeRangeMask
    }
  },
  directives: {
    'mask': VueMaskDirective
  },
  computed: {
    inputValue: {
      get() {
        return this.valueStr;
      },
      set(val) {
        this.valueStr = val;
        const timestamp = this.getTimestampValue(this.valueStr);
        if (timestamp >= 0) {
          if (timestamp !== this.value) {
            this.$emit('input', timestamp);
          }
        } else {
          if (this.value !== null) {
            this.$emit('input', null);
          }
        }
      }
    } 
  },
  methods: {
    getValidationState() {
      return null;
    },
    getTimestampValue(valueStr) {
      const elements = valueStr.split(':');
      if (elements.length !== 2) {
        return null;
      }
      const hours = Number.parseInt(elements[0]);
      if (elements[1].length !== 2) {
        return null;
      }
      const minutes = Number.parseInt(elements[1]);
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59 ) {
        return null;
      }
      return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          const date = dayjs.utc(value);
          const valueStr = date.format('HH:mm');
          if (this.inputValue !== valueStr) {
            this.inputValue = valueStr;
          }
        } else {
          this.inputValue = '';
        }
      }
    }
  }
}
</script>