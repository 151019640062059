<template>
  <div>
    <b-row class="mb-2">
      <!--<b-col>
        <b-form-group>
          <b-form-radio-group
            v-model="studentListFilter"
            :options="[{text:'All', value:'all'}, {text:'To Update', value:'needs-update'}]"
            size="sm"
            button-variant="outline-primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-col>-->
      <b-col >
        <div class="w-100">
          <b-input-group>
            <b-input type="search" placeholder="Type to search"
              v-model="searchQueryText" debounce="500" />
            <template #append>
              <b-input-group-text><b-icon-search /></b-input-group-text>
            </template>
          </b-input-group>
          <!--<div class="w-25">
            <FormGroupSelect :rules="{ required: true }"
              :field="{
                name: 'group',
                label: 'Group',
                placeholder: 'Enter group name',
                description: ''
              }"
              v-model="group" />
          </div>-->
        </div>
      </b-col>
    </b-row>

    <b-overlay :show="$store.getters.isLoadingSearchResults">
      <b-list-group v-if="selectedStudent" class="mb-2">
        <b-list-group-item
          :class="[...itemClass(selectedStudent), 'shadow']"
          @click="handleStudentClick(selectedStudent)">
          <div class="d-flex">
            <div class="mt-auto mb-auto d-none d-md-block">
              <b-avatar variant="secondary" />
            </div>
            <div class="ml-3">
              <span class="font-weight-bold">{{fullName(selectedStudent)}}</span>
              <small> {{groupName(selectedStudent)}}</small><br>
              <small class="mb-0 d-none d-sm-block">Updated: {{lastUpdated(selectedStudent)}}</small>
            </div>
          </div>
          <div v-if="selectedId === selectedStudent.id" class="mt-auto mb-auto float-right d-none d-md-block">
            <b-icon-arrow-right />
          </div>
        </b-list-group-item>
      </b-list-group>

      <div style="overflow: scroll; border-color: rgb(0,0,0,0.1); border-style: solid; border-width:1px; border-radius: 5px">
        <b-list-group>
          <b-list-group-item v-for="student in students" :key="student.id"
            :variant="itemVariant(student)"
            :class="itemClass(student)"
            style="cursor:pointer"
            @click="handleStudentClick(student)">
            <div class="d-flex">
              <div class="mt-auto mb-auto d-none d-md-block">
                <b-avatar :variant="itemVariant(student)" />
              </div>
              <div class="ml-3">
                <span class="font-weight-bold">{{fullName(student)}}</span>
                <small> {{groupName(student)}}</small><br>
                <small class="mb-0 d-none d-sm-block">Updated: {{lastUpdated(student)}}</small>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <b-pagination
        class="mt-2"
        v-model="searchPage"
        :total-rows="$store.getters.searchTotalFound"
        :per-page="10"
      ></b-pagination>

    </b-overlay>

    <div class="mt-2 mb-5">
      <small>Showing {{$store.getters.searchFoundStudentIds.length}} students (Total found: {{$store.getters.searchTotalFound}})</small>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import FormGroupSelect from '@/components/ui/FormGroupSelect';
dayjs.extend(relativeTime);
export default {
  props: {
    selectedId: {
      type: String
    }
  },
  components: {
    // FormGroupSelect
  },
  data() {
    return {
      studentListFilter: 'all',
      group: null
    }
  },
  computed: {
    students() {
      return this.$store.getters.searchFoundStudents;
      // const students = this.$store.getters.students;
      // return Object.keys(students).map(id => students[id]);
    },
    selectedStudent() {
      const students = this.$store.getters.students;
      return students[this.selectedId];
    },
    searchPage: {
      get() {
        return this.$store.getters.searchPageNumber + 1;
      },
      set(value) {
        this.$store.commit('studentsSetPage', value - 1);
        this.$store.dispatch('searchStudents');
      }
    },
    searchQueryText: {
      get() {
        return this.$store.getters.searchQuery.text;
      },
      set(value) {
        this.$store.commit('setSearchQueryText', value);
        this.$store.dispatch('searchStudents');
      }
    }
  },
  methods: {
    itemVariant(student) {
      if (!this.selectedId) {
        return null;
      } else {
        if (student.id === this.selectedId) {
          return 'primary';
        } else {
          return 'light';
        }
      }
    },
    itemClass(student) {
      const classes = ['d-flex', 'd-flex', 'justify-content-between'];
      if (student.id === this.selectedId) {
        classes.push('shadow-sm');
      } else {
        classes.push('shadow-sm');
      }
      return classes;
    },
    groupName(student) {
      if (!student || !student.group) {
        return null;
      }
      return `(${this.$store.getters.group(student.group.id).name})`;
    },
    lastUpdated(student) {
      if (!student) {
        return null;
      }
      if (!student.scheduleUpdatedAt) {
        return 'Never';
      } else {
        return dayjs(student.scheduleUpdatedAt.seconds * 1000).fromNow();
      }
    },
    fullName(student) {
      const elements = [];
      if (student.firstName && student.firstName.length > 0) {
        elements.push(student.firstName);
      }
      if (student.middleName && student.middleName.length > 0) {
        elements.push(student.middleName.substring(0, 1) + '.');
      }
      if (student.lastName && student.lastName.length > 0) {
        elements.push(student.lastName);
      }
      if (elements.length === 0) {
        return '???';
      }
      return elements.join(' ');
    },
    handleStudentClick(student) {
      this.$emit('select', student);
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchGroups');
    await this.$store.dispatch('searchStudents');
  }
}
</script>