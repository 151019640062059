<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <h2>Students</h2>
      <div>
        <b-button variant="outline-primary"
          @click="$refs.creator.show()">
          New Student
          <b-icon-plus/>
        </b-button>
      </div>
    </div>
    <hr>
    <div>
      <b-row v-if="selectedStudentId">
        <b-col cols="4">
          <StudentList :selected-id="selectedStudentId" @select="handleStudentSelect"/>
        </b-col>
        <b-col cols="8">
          <b-card class="shadow">
            <StudentDetails :student-id="selectedStudentId" @close="selectedStudentId = null" />
          </b-card>
        </b-col>
      </b-row>
      <div v-else>
        <StudentList :selected-id="selectedStudentId" @select="handleStudentSelect"/>
      </div>
    </div>
    <b-modal ref="creator" title="New Student" ok-title="Confirm" hide-footer>
      <StudentEditor ref="editor"
        @submit="handleCreatorFinish"
        @cancel="handleCreatorFinish" />
    </b-modal>
  </div>
</template>
<script>
import StudentEditor from '@/components/StudentEditor';
import StudentDetails from '@/components/StudentDetails';
import StudentList from '@/components/StudentList';
export default {
  components: {
    StudentEditor,
    StudentDetails,
    StudentList
  },
  data() {
    return {
      selectedStudentId: null
    }
  },
  methods: {
    handleStudentSelect(student) {
      this.selectedStudentId = student.id;
    },
    handleCreatorFinish(student) {
      this.$refs.creator.hide();
      if (student) {
        this.selectedStudentId = student.id;
      }
    }
  }
}
</script>