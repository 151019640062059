<template>
  <div>
    <b-row>
      <b-col cols="auto" class="mb-3">
        <b-overlay :show="$store.getters.isFetchingMonthlyScheduleDays" opacity="0.3">
          <v-date-picker
            ref="calendar"
            mode="date"
            :attributes="attributes"
            :model-config="{
              type: 'string',
              mask: 'YYYY-MM-DD',
            }"
            timezone="UTC"
            show-weeknumbers="right"
            :first-day-of-week="2"
            @update:from-page="onCalendarPageUpdate"
            v-model="selectedDate" />
        </b-overlay>
      </b-col>
      <b-col :class="!selectedDate ? 'h-100 mb-auto mt-auto' : ''">
        <b-overlay :show="$store.getters.isFetchingMonthlyScheduleDays" opacity="0.3">
          <div v-if="!selectedDate" class="text-center text-muted">
            <b-icon-exclamation-triangle font-scale="4" class="mb-2" /><br>
            <span>Select a date</span>
          </div>
          <b-card v-else class="h-100" :title="dayjs(selectedDate).format('MMMM D, YYYY')"
            :sub-title="'Timezone: ' + student.timezone">
            <hr>
            <div v-if="isEditDisabled" class="mb-2">
              <b-alert variant="secondary" show>Can't edit dates in the past</b-alert>
            </div>
            <div class="mb-3 pt-1">
              <b-form-radio-group
                v-model="dayType"
                :options="dayTypeOptions"
                :button-variant="isEditDisabled ? 'outline-secondary' : 'outline-primary'" size="sm" buttons />
            </div>
            <div v-if="dayType === 'working-day'" class="mb-1">
              <div class="mb-2">
                <span>Working hours ({{student.timezone}})</span>
              </div>
              <div v-if="workingHours" class="mb-0">
                <div v-for="(workingHours, index) in workingHours" :key="index">
                  <b-row no-gutters style="width: 210px" class="mb-0">
                    <b-col class="d-flex">
                      <FormTime v-model="workingHours.from"
                        :field="{}" size="sm" class="mr-1" @input="workingHoursDirty = true" :disabled="isEditDisabled" />
                    </b-col>
                    <b-col cols="auto" class="ml-2 mr-2">
                      <span>-</span>
                    </b-col>
                    <b-col class="d-flex">
                      <FormTime :field="{}" v-model="workingHours.to"
                        size="sm" class="mr-1" @input="workingHoursDirty = true" :disabled="isEditDisabled" />
                    </b-col>
                    <b-col>
                      <b-button v-if="!isEditDisabled" size="sm" class="ml-2" variant="outline-danger"
                        @click="onRemoveWorkingHours(index)">
                        <b-icon-trash />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="mb-5">
                <b-button v-if="!isEditDisabled" class="mr-2"
                  variant="outline-primary" size="sm" @click="createWorkingHoursSlot">
                  Add hours<b-icon-plus />
                </b-button>
                <b-button v-if="!isEditDisabled"
                  :variant="workingHoursDirty ? 'success' : 'outline-secondary'" size="sm" class="ml-3" @click="saveWorkingHours"
                  :disabled="!workingHoursDirty || $store.getters.isWritingScheduleDay || !workingHoursValid">
                  Save
                  <b-spinner v-if="$store.getters.isWritingScheduleDay" class="ml-1" small />
                  <b-icon-check v-else />
                </b-button>
                <div v-if="isEditDisabled && (!workingHours || workingHours.length === 0)">
                  <small class="text-muted">No hours provided</small>
                </div>
              </div>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import FormTime from '@/components/ui/FormTime';
dayjs.extend(tz);
dayjs.extend(utc);
export default {
  props: {
    studentId: {
      type: String
    }
  },
  components: {
    FormTime
  },
  computed: {
    isEditDisabled() {
      if (dayjs(this.selectedDate).isBefore(dayjs().startOf('day'))) {
        return true;
      } else {
        return false;
      }
    },
    dayTypeOptions() {
      const options = [
        {text:'Day Off',value:'day-off'},
        {text:'Working Day',value:'working-day'}
      ];
      if (this.isEditDisabled || this.$store.getters.isWritingScheduleDay) {
        return options.map(opt => {
          if (opt.value !== this.dayType) {
            opt.disabled = true;
          }
          return opt;
        });
      } else {
        return options;
      }
    },
    student() {
      return this.$store.getters.student(this.studentId);
    },
    attributes() {
      const dayOffs = [];
      const emptyWorkingDays = [];
      const workingDays = [];
      const month = this.pageMonth;
      const year = this.pageYear;
      if (!month || !year) {
        return [];
      }

      let d = dayjs.utc(`${year}-${month}-1`);
      for (let i = 0; i < 32; i++) {
        if (d.month() + 1 !== month) {
          break;
        }
        const scheduleDay = this.getScheduleDay(d.date(), d.month() + 1, d.year());
        if (scheduleDay) {
          if (scheduleDay.type === 'day-off') {
            dayOffs.push(dayjs.tz(d, 'UTC').toDate());
          } else if (scheduleDay.type === 'working-day') {
            if (Array.isArray(scheduleDay.workingHours) && scheduleDay.workingHours.length > 0) {
              workingDays.push(dayjs.tz(d, 'UTC').toDate());
            } else {
              emptyWorkingDays.push(dayjs.tz(d, 'UTC').toDate());
            }
          }
        }
        d = d.add(1, 'day');
      }
      return [{
        key: 'today',
        order: -1,
        highlight: {
          color: 'red',
          fillMode: 'outline'
        },
        dates: new Date()
      }, {
        key: 'days-off',
        order: -2,
        highlight: {
          color: 'green',
          fillMode: 'solid',
        },
        dates: dayOffs
      }, {
        key: 'working-days',
        order: -2,
        highlight: {
          color: 'gray',
          fillMode: 'solid',
        },
        dates: workingDays
      }, {
        key: 'empty-working-days',
        order: -2,
        highlight: {
          color: 'gray',
          fillMode: 'light',
        },
        dates: emptyWorkingDays
      }];
    },
    selectedDateDetails() {
      const dateElements = dayjs(this.selectedDate).format('YYYY-MM-DD').split('-');
      const date = Number(dateElements[2]);
      const month = Number(dateElements[1]);
      const year = Number(dateElements[0]);
      return { date, month, year };
    },
    scheduleDay() {
      const { date, month, year } = this.selectedDateDetails;
      return this.getScheduleDay(date, month, year);
    },
    dayTypeName() {
      const type = this.dayType;
      switch (type) {
        case 'day-off':
          return 'Day Off';
        case 'working-day':
          return 'Working Day';
        default:
          return 'Unknown';
      }
    },
    dayType: {
      get() {
        const scheduleDay = this.scheduleDay;
        if (scheduleDay) {
          return scheduleDay.type;
        } else {
          return null;
        }
      },
      set(type) {
        const { date, month, year } = this.selectedDateDetails;
        const scheduleDay = this.scheduleDay;
        if (!type) {
          return;
        }
        if (scheduleDay && scheduleDay.type === type) {
          return;
        }
        const data = Object.assign(scheduleDay || {}, {
          type: type,
          date: date,
          month: month,
          year: year,
          timezone: this.student.timezone,
          timestamp: dayjs.utc(`${year}-${month}-${date}`).toDate(),
          student: this.studentId
        });
        if (type === 'working-day') {
          data.workingHours = this.workingHours || data.workingHours || [];
        } else if (type === 'day-off') {
          data.workingHours = null;
        } 
        this.$store.dispatch('setStudentScheduleDay', data); 
        this.$store.dispatch('fetchStudent', this.studentId);
      }
    },
    workingHoursValid() {
      const workingHours = this.workingHours;
      if (!workingHours) {
        return false;
      }
      for (let i = 0; i < workingHours.length; i++) {
        const range = workingHours[i];
        if (range.from === undefined || range.from === null ||
          range.to === undefined || range.to === null) {
          return false;
        }
        /*if (range.from > range.to) {
          return false;
        }*/
      }
      return true;
    }
  },
  data() {
    return {
      selectedDate: new Date(),
      workingHours: [],
      workingHoursDirty: false,
      pageMonth: null,
      pageYear: null,
      dayjs: dayjs
    }
  },
  async mounted() {
  },
  methods: {
    async fetchScheduleForMonth() {
      await this.$store.dispatch('fetchStudentScheduleDayForMonth', {
        month: this.pageMonth,
        year: this.pageYear,
        studentId: this.studentId
      });
    },
    async onCalendarPageUpdate({ month, year }) {
      this.pageMonth = month;
      this.pageYear = year;
      this.fetchScheduleForMonth();
    },
    onRemoveWorkingHours(index) {
      this.workingHours.splice(index, 1);
      this.workingHoursDirty = true;
    },  
    getScheduleDay(date, month, year) {
      const scheduleDay = this.$store.getters.studentScheduleDay(this.studentId, date, month, year);
      return scheduleDay;
    },
    async saveWorkingHours() {
      const { date, month, year } = this.selectedDateDetails;
      const scheduleDay = this.getScheduleDay(date, month, year);
      scheduleDay.workingHours = this.workingHours;
      await this.$store.dispatch('setStudentScheduleDay', scheduleDay); 
      this.$store.dispatch('fetchStudent', this.studentId);
      this.workingHoursDirty = false;
    },
    async createWorkingHoursSlot() {
      this.workingHoursDirty = true;
      this.workingHours.push({
        from: null,
        to: null
      }); 
    }
  },
  watch: {
    async studentId() {
      await this.fetchScheduleForMonth();
      this.workingHoursDirty = false;
      if (this.scheduleDay) {
        this.workingHours = JSON.parse(JSON.stringify(this.scheduleDay.workingHours || []));
      }
    },
    /*selectedDate() {
      this.workingHoursDirty = false;
      this.workingHours = [];
      if (this.scheduleDay) {
        this.$nextTick((function () {
          this.workingHours = JSON.parse(JSON.stringify(this.scheduleDay.workingHours || []));
        }).bind(this));
      }
    },*/
    scheduleDay() {
      this.workingHoursDirty = false;
      this.workingHours = [];
      if (this.scheduleDay) {
        this.$nextTick((function () {
          this.workingHours = JSON.parse(JSON.stringify(this.scheduleDay.workingHours || []));
        }).bind(this));
      }
    }
  }
}
</script>