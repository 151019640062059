var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.isNew)?_c('p',{staticClass:"font-weight-bold"},[_vm._v("Attention: Verify information before saving!")]):_vm._e(),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('FormInput',{attrs:{"rules":{required:true, alpha_spaces: true},"field":{
              name: 'firstName',
              label: 'First Name',
              placeholder: 'Enter first name'
            }},model:{value:(_vm.student.firstName),callback:function ($$v) {_vm.$set(_vm.student, "firstName", $$v)},expression:"student.firstName"}})],1),_c('b-col',{staticClass:"ml-2"},[_c('FormInput',{attrs:{"rules":{required:true, alpha_spaces: true},"field":{
              name: 'lastName',
              label: 'Last Name',
              placeholder: 'Enter last name'
            }},model:{value:(_vm.student.lastName),callback:function ($$v) {_vm.$set(_vm.student, "lastName", $$v)},expression:"student.lastName"}})],1)],1),_c('FormInput',{attrs:{"rules":{ alpha_spaces: true },"field":{
          name: 'middleName',
          label: 'Middle Name',
          placeholder: 'Enter middle name if applicable'
        }},model:{value:(_vm.student.middleName),callback:function ($$v) {_vm.$set(_vm.student, "middleName", $$v)},expression:"student.middleName"}}),_c('FormInput',{attrs:{"rules":{ required: true, email: true },"field":{
          name: 'email',
          label: 'Contact Email',
          placeholder: 'Enter email',
          description: 'Double check email and make sure there is no typo.'
        }},model:{value:(_vm.student.email),callback:function ($$v) {_vm.$set(_vm.student, "email", $$v)},expression:"student.email"}}),_c('FormGroupSelect',{ref:"groupSelect",attrs:{"rules":{ required: true },"field":{
          name: 'group',
          label: 'Group',
          placeholder: 'Enter group name',
          description: ''
        }},model:{value:(_vm.groupId),callback:function ($$v) {_vm.groupId=$$v},expression:"groupId"}}),(!_vm.isNew)?_c('div',{staticClass:"mb-3"},[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('span',[_vm._v("Warning! Changing country or timezone will make all previously entered schedules unavailable")])])],1):_vm._e(),_c('FormSelect',{attrs:{"rules":{ required: true },"field":{
          name: 'country',
          label: 'Country',
          placeholder: 'Select country'
        },"options":_vm.countryOptions},model:{value:(_vm.student.country),callback:function ($$v) {_vm.$set(_vm.student, "country", $$v)},expression:"student.country"}}),(_vm.student.country)?_c('FormSelect',{attrs:{"rules":{ required: true },"field":{
          name: 'timezone',
          label: 'Timezone',
          placeholder: 'Select timezone'
        },"options":_vm.timezoneOptions},model:{value:(_vm.student.timezone),callback:function ($$v) {_vm.$set(_vm.student, "timezone", $$v)},expression:"student.timezone"}}):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save "),_c('b-icon-check')],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }