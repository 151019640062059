<template>
  <div class="root">
    <div v-if="!studentId" class="justify-content-center d-flex">
      <h5>No student selected</h5>
    </div>
    <div>
      <div class="d-flex justify-content-between">
        <div>
          <h5>
            {{fullName(student)}}
          </h5>
          <span>{{groupName(student)}}</span>
        </div>
        <!--<b-btn-close @click="handleClose" />-->
        <b-button size="sm" variant="light" @click="$refs.editorWindow.show()">Edit <b-icon-pencil-square /></b-button>
      </div>
      <hr>
      <div>
        <ScheduleEditor :student-id="studentId" />
      </div>


    </div>
    <b-modal ref="editorWindow" title="Edit Student" ok-title="Save" hide-footer>
      <StudentEditor ref="editor"
        :student-id="student.id"
        @submit="handleEditorFinish"
        @cancel="handleEditorFinish" />
    </b-modal>
  </div>
</template>
<script>
import countriesAndTz from 'countries-and-timezones';
import ScheduleEditor from '@/components/ScheduleEditor';
import StudentEditor from '@/components/StudentEditor';
export default {
  props: {
    studentId: {
      type: String
    }
  },
  components: {
    ScheduleEditor,
    StudentEditor
  },
  data() {
    return {
      selectedDate: null
    }
  },
  computed: {
    student() {
      return this.$store.getters.student(this.studentId);
    }
  },
  methods: {
    groupName(student) {
      if (!student || !student.group) {
        return null;
      }
      return `${this.$store.getters.group(student.group.id).name}`;
    },
    country(code) {
      const countries = countriesAndTz.getAllCountries();
      if (!countries[code]) {
        return 'Unknown';
      }
      return countries[code].name;
    },
    fullName(student) {
      const elements = [];
      if (student.firstName && student.firstName.length > 0) {
        elements.push(student.firstName);
      }
      if (student.middleName && student.middleName.length > 0) {
        elements.push(student.middleName);
      }
      if (student.lastName && student.lastName.length > 0) {
        elements.push(student.lastName);
      }
      if (elements.length === 0) {
        return '???';
      }
      return elements.join(' ');
    },
    handleClose() {
      this.$emit('close');
    },
    handleEditorFinish() {
      this.$refs.editorWindow.hide();
    }
  }
}
</script>
<style lang="scss" scoped>
.root {
}
</style>
